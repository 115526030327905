import "./App.css";
import { Suspense, lazy } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";

import notify from "./utils/toastr";
import { getCookie, deleteCookie } from "./utils/login";

import routes from "./pages/index";

const Login = lazy(() => import("./pages/Login"));

function App() {
  const navigate = useNavigate();

  window.addEventListener("load", function () {
    if (window.ethereum) {
      // Detect Metamask Account change
      window.ethereum.on("accountsChanged", async (accounts) => {
        notify.warning(`Your Meta Mask account has been changed, please conncet your wallet again.`);
        deleteCookie("token");
        navigate("wallet-connect");
      });

      // Detect Metamask chain change
      window.ethereum.on("chainChanged", async (chainId) => {
        notify.warning(`Your Network has been changed, please conncet your wallet again.`);
        deleteCookie("token");
        navigate("wallet-connect");
      });
    }
  });

  return (
    <Suspense
      fallback={
        <div className="container">
          <div className="set1">
            <div className="ball"></div>
            <div className="ball"></div>
          </div>
          <div className="set2">
            <div className="ball"></div>
            <div className="ball"></div>
          </div>
        </div>
      }
    >
      <Routes>
        {routes.map((data, index) => (
          <Route
            onUpdate={() => window.scrollTo(0, 0)}
            exact={true}
            path={data.path}
            element={getCookie("login") ? data.component : data.path === "bundle-create" ? data.component : <Login />}
            key={index}
          />
        ))}
      </Routes>
    </Suspense>
  );
}

export default App;
