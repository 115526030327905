import { lazy } from "react";

const Home = lazy(() => import("./Home"));
const Explore = lazy(() => import("./Explore"));
const AssetDetail = lazy(() => import("./AssetDetail"));
const Account = lazy(() => import("./Account"));
const WalletConnect = lazy(() => import("./WalletConnect"));
const CreateItem = lazy(() => import("./CreateItem"));
const EditItem = lazy(() => import("./EditItem"));
const EditProfile = lazy(() => import("./EditProfile"));
const FAQ = lazy(() => import("./FAQ"));
const Contact = lazy(() => import("./Contact"));
const BundleSell = lazy(() => import("./BundleSell"));
const BundleList = lazy(() => import("./BundleList"));
const CreateBundleRollerCoaster = lazy(() => import("./CreateBundleRollerCoaster"));
const BundleDetail = lazy(() => import("./BundleDetail"));

// Roller Coaster
const RollerCoasterAssets = lazy(() => import("../roller-coaster/AssetsRC"));
const RollerCoasterAssetDetail = lazy(() => import("../roller-coaster/AssetDetailRC"));

const routes = [
  { path: "/", component: <Home /> },
  { path: "/explore", component: <Explore /> },
  // { path: "/explore-03", component: <Explore03 /> },
  { path: "/bundle-sell/:assetIds", component: <BundleSell /> },
  { path: "/bundles", component: <BundleList /> },
  { path: "/bundles/:bundleId", component: <BundleDetail /> },
  { path: "/asset/:nftId", component: <AssetDetail /> },
  { path: "/account/:publicAddress", component: <Account /> },
  { path: "/wallet-connect", component: <WalletConnect /> },
  { path: "/create-item", component: <CreateItem /> },
  { path: "/edit-mint-item/:nftId", component: <EditItem /> },
  { path: "/edit-profile/:publicAddress", component: <EditProfile /> },
  { path: "/faq", component: <FAQ /> },
  { path: "/contact", component: <Contact /> },
  { path: "/bundle-create", component: <CreateBundleRollerCoaster /> },

  // Roller Coaster
  { path: "/roller-coaster/game-assets", component: <RollerCoasterAssets /> },
  { path: "/roller-coaster/game-assets/:assetId", component: <RollerCoasterAssetDetail /> },
];

export default routes;
